.StripeElement {
    display: block;
    margin-top: 5px;
    padding: 9px 12px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2dae1;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
  }
  .StripeElement--focus {
    color: #495057;
    background-color: #fff;
    border-color: #969696;
    outline: 0;
  }
  .StripeElement::-webkit-input-placeholder {
    color: #a6a6a6;
  }
  .StripeElement::-ms-input-placeholder {
    color: #a6a6a6;
  }
  .StripeElement::placeholder {
    color: #a6a6a6;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
